import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import WalletApi from 'apis/wallet';
import { useRequest } from 'ahooks';
import Header from 'components/Header/Header';
import worldImg from 'assets/imgs/worldmap-bg.png';

import styles from './StakingData.module.scss';
import WalletCard from '../Wallet/WalletCard';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Input, message, Spin, Button, Tag } from 'antd';
import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { signMessage } from './polkadot';
import moment from 'moment';
export default function StakingData() {
  const [searchDeeperChain, setSearchDeeperChain] = useState('');
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navitage = useNavigate();
  const deeperChain = params.get('deeperChain');

  const {
    run: runGetDetail,
    data,
    loading,
  } = useRequest(WalletApi.getDetailByDeeperChain, {
    manual: true,
    onError(e) {
      console.log(e);
      message.error(t('Enter Deeper Chain Address'));
    },
  });

  const {
    run: runGetReleaseRecord,
    data: releaseRecordList = null,
    loading: releaseRecordLoading,
  } = useRequest(
    (signData) => {
      return axios
        .post(
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:7001/chain/releaseRecord'
            : 'https://api-device.deeper.network/chain/releaseRecord',
          {
            ...signData,
            address: deeperChain,
          },
        )
        .then((res) => {
          if (res.data.success === false) {
            message.error(res.data.reason);
            return null;
          }
          return res.data;
        });
    },
    {
      manual: true,
    },
  );

  const onSearch = () => {
    console.log(searchDeeperChain);
    navitage(`/stakingData?deeperChain=${searchDeeperChain}`);
  };

  useEffect(() => {
    if (deeperChain) {
      runGetDetail(deeperChain);
    }
  }, [deeperChain]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      <Header></Header>
      <div className={styles['search-box']}>
        <Input.Search
          placeholder={t('Enter Deeper Chain Address')}
          allowClear
          enterButton={t('Search')}
          size='large'
          onSearch={onSearch}
          onChange={(e) => setSearchDeeperChain(e.target.value)}
        ></Input.Search>
      </div>
      <div className={styles['staking-box']}>
        <div className={styles['card-box']}>
          {loading && (
            <div className={styles['loading-box']}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          )}
          {deeperChain && (
            <WalletCard
              single
              data={{
                ...data,
                deeperChain,
              }}
            />
          )}
        </div>
      </div>
      {deeperChain && (
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              border: '1px solid #eee',
              padding: 20,
              borderRadius: 10,
              width: 500,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 style={{ marginBottom: 20, fontWeight: 'bold' }}>
                {t('Release Record')}
              </h3>
              <Button
                type='primary'
                onClick={() => {
                  signMessage(deeperChain, moment().format('YYYY-MM-DD'))
                    .then((signData) => {
                      console.log(signData);
                      runGetReleaseRecord(signData);
                    })
                    .catch((e) => {
                      console.log(e);
                      message.error(e.message);
                    });
                }}
              >
                {t('Sign to get release record')}
              </Button>
            </div>
            <ReleaseRecord
              releaseRecordLoading={releaseRecordLoading}
              releaseRecordList={releaseRecordList}
              deeperChain={deeperChain}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function ReleaseRecord({ releaseRecordLoading, releaseRecordList }) {
  const { t } = useTranslation();
  if (releaseRecordLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin></Spin>
      </div>
    );
  }

  if (!releaseRecordList) {
    return null;
  }

  if (releaseRecordList?.length === 0) {
    return (
      <div
        style={{
          textAlign: 'center',
          color: '#999',
          marginTop: 20,
        }}
      >
        {t('No release record')}
      </div>
    );
  }
  const showList = Object.assign([], releaseRecordList).reverse();
  return showList?.map((item) => (
    <div
      key={item._id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        border: '1px solid #eee',
        padding: 20,
        borderRadius: 10,
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{t('Release Date')}:</div>
        <div>{moment(item.createdAt).format('YYYY-MM-DD')}</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{t('Release Amount')}:</div>
        <div>{item.releaseStaking ? item.releaseStaking / 1e18 : 0} DPR</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{t('Credit')}:</div>
        <div>
          {item.credit} <ArrowRightOutlined />{' '}
          {item.credit - (item.stakingCredit || 0)}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{t('Status')}:</div>
        {item.releaseStaking ? (
          <div>
            {item.setHash ? (
              <Tag color='success'>{t('Success')}</Tag>
            ) : (
              <Tag color='warning'>{t('Pending')}</Tag>
            )}
          </div>
        ) : (
          <div>
            <Tag color='success'>{t('Success')}</Tag>
          </div>
        )}
      </div>
    </div>
  ));
}
