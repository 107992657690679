import {
  web3Accounts,
  web3Enable,
  web3FromSource,
} from '@polkadot/extension-dapp';
const {
  decodeAddress,
  cryptoWaitReady,
  signatureVerify,
} = require('@polkadot/util-crypto');
const { u8aToHex } = require('@polkadot/util');

export const signMessage = async (address, message) => {
  try {
    const extensions = await web3Enable('deeper staking');

    if (extensions.length === 0) {
      throw new Error('Please install Polkadot.js extension');
    }
    const allAccounts = await web3Accounts();

    if (allAccounts.length === 0) {
      throw new Error('No available accounts found');
    }
    console.log('All accounts:', allAccounts);

    const accountMeta =
      process.env.NODE_ENV === 'development'
        ? allAccounts[0]
        : allAccounts.find((item) => item.address === address);
    if (!accountMeta) {
      throw new Error(`Please connect ${address} to polkadot.js extension`);
    }

    // Get the first account (or let the user choose an account)
    const account = accountMeta;
    console.log('Selected account:', account);

    // Get the injector for the selected account
    const injector = await web3FromSource(account.meta.source);

    // Sign the message
    const signRaw = injector?.signer?.signRaw;

    if (!signRaw) {
      throw new Error('Current account does not support message signing');
    }

    // Prepare the data to be signed
    const messageString =
      typeof message === 'string' ? message : JSON.stringify(message);

    const { signature } = await signRaw({
      address: account.address,
      data: messageString,
      type: 'bytes', // Use 'bytes' type
    });

    console.log('Signature result:', signature);
    const signedData = {
      signature,
      address: account.address,
      message: messageString,
    };
    console.log('Signed data:', signedData);
    const isValid = await verifySignature(signedData);
    console.log('Verification result:', isValid);

    return signedData;
  } catch (error) {
    console.error('Signing failed:', error);
    throw error;
  }
};

export const verifySignature = async (signedData) => {
  await cryptoWaitReady();
  try {
    const { signature, message, address } = signedData;

    console.log('Verification data:', {
      message: message,
      signature: signature,
      address: address,
    });
    const publicKey = decodeAddress(address);
    const hexPublicKey = u8aToHex(publicKey);

    const validData = signatureVerify(message, signature, hexPublicKey);
    console.log('Verification result:', validData);

    return {
      isValid: validData.isValid,
      address,
      message: message,
    };
  } catch (error) {
    console.error('Verification failed:', error);
    throw error;
  }
};
